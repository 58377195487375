
import { Component, Vue } from 'vue-property-decorator'
import { Invoice } from '@/models'
import { useUserStore } from '@/stores/user'
import InvoiceTable from '@/views/invoices/components/InvoiceTable.vue'
import FiltersDrawer from '@/views/invoices/components/FiltersDrawer.vue'

@Component({
  name: 'Invoices',
  components: {
    InvoiceHeader: () => import('./components/InvoiceHeader.vue'),
    InvoiceTable: () => import('./components/InvoiceTable.vue'),
    InvoiceAsideDetails: () => import('./components/InvoiceAsideDetails.vue'),
    FiltersDrawer: () => import('./components/FiltersDrawer.vue')
  }
})
export default class extends Vue {
  private userStore: any = useUserStore()

  private invoiceSelected: Invoice | null = null
  private listQuery = {
    number: null,
    orderNumber: null,
    paymentMode: [],
    dateRange: null,
    sellerId: null,
    buyerId: null,
    paymentStatus: null
  }

  private wholesalerInvoiceType = 'seller'

  get isInvoiceTypeSeller(): boolean {
    return this.wholesalerInvoiceType === 'seller'
  }

  get isWholesaler() {
    return this.userStore.roles.includes('wholesaler')
  }

  setFilters(filters: any) {
    this.listQuery = { ...filters }
  }

  resetFilters() {
    (this.$refs.invoiceFilters as FiltersDrawer).resetFilters()
  }

  setInvoicesTableType() {
    this.resetFilters()
    this.wholesalerInvoiceType = this.isInvoiceTypeSeller ? 'buyer' : 'seller'
  }

  setInvoiceSelected(invoice: Invoice | null) {
    this.invoiceSelected = invoice
  }

  closeInvoiceDetails() {
    (this.$refs.invoiceTable as InvoiceTable).selectInvoice(null)
  }

  updateSelectedInvoice(invoice: Invoice) {
    (this.$refs.invoiceTable as InvoiceTable).updateSelectedInvoice(invoice)
  }

  refresh() {
    ;(this.$refs.invoiceFilters as FiltersDrawer).resetFilters()
  }
}
